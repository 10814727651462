import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncViewAllPostsGuest({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/viewAllPostsDropdownGuest"));
  // const AsyncLoad = loadable(() => import("../page/viewAllPosts"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncViewAllPostsGuest;
