import React from "react";
import YoutubeEmbed from "../../common/youtubeEmbed";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Videos() {
  return (
    <Row>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          {/* <span role="img" aria-label="celebrate">
            🎉
          </span>{" "} */}
          How to Apply Jobs?
        </p>
        <YoutubeEmbed youtubeid="QkhVyhWsnTs" />
        <div className="mt-4 container px-4 col-lg-6 text-center">
          <Link
            className="font-weight-bold btn-success btn-lg btn-block mb-2 roundcornernocolorbutton"
            to={`/hiring`}>
            Apply Jobs
          </Link>
          <p className="defaultstandardfontsize pb-0 mb-0 mt-0 mr-0">
            <span className="text-danger">*</span>Direct to Agency
          </p>
        </div>
      </Col>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          {/* <span role="img" aria-label="rocket">
            🚀
          </span>{" "} */}
          How to Download FREE CV?
        </p>
        <YoutubeEmbed youtubeid="qufEkqYixWE" />
      </Col>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          {/* <span role="img" aria-label="rocket">
            🚀
          </span>{" "} */}
          How to Edit SeamanBio CV?
        </p>
        <YoutubeEmbed youtubeid="qz9IrUzp-Y4" />
      </Col>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          {/* <span role="img" aria-label="celebrate">
            🎉
          </span>{" "} */}
          How to Add Signature in SeamanBio CV?
        </p>
        <YoutubeEmbed youtubeid="AKiFjgAb0IE" />
      </Col>
      {/* <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          <span role="img" aria-label="construct">
            👷🏻‍♂️
          </span>{" "}
          How to Create FREE CV?
        </p>
        <YoutubeEmbed youtubeid="KRGYhyDx79U" />
      </Col> */}

      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          {/* <span role="img" aria-label="celebrate">
            🎉
          </span>{" "} */}
          How to Upload Existing CV?
        </p>
        <YoutubeEmbed youtubeid="qU-XrEC6AMY" />
      </Col>
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          {/* <span role="img" aria-label="celebrate">
            🎉
          </span>{" "} */}
          How to Change Color and Format of CV?
        </p>
        <YoutubeEmbed youtubeid="pbk14tPFXrE" />
      </Col>
      {/* <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          <span role="img" aria-label="celebrate">
            🎉
          </span>{" "}
          How to Create FREE Cover Letter
        </p>
        <YoutubeEmbed youtubeid="8OXizAYxs0E" />
      </Col> */}
      <Col xs={12}>
        <p className="font-weight-bold averagefontsize pt-4 pb-0 mb-0 text-left">
          {/* <span role="img" aria-label="fire">
            🔥
          </span>{" "} */}
          How to Use AI for Career Objective?
        </p>
        <YoutubeEmbed youtubeid="pn93ib5wfxQ" />
      </Col>
    </Row>
  );
}

export default Videos;
