import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncViewAgencyAllJobPostsGuest({ ...rest }) {
  const AsyncLoad = loadable(() =>
    import("../page/viewAgencyAllJobPostsGuest")
  );
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncViewAgencyAllJobPostsGuest;
