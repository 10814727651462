import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firebase-firestore";
import "firebase/analytics";
import "firebase/messaging";
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURE_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
};
const fire = firebase.initializeApp(firebaseConfig);
const googleProvider = new firebase.auth.GoogleAuthProvider();
const yahooProvider = new firebase.auth.OAuthProvider("yahoo.com");
// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
fire.analytics();
export { googleProvider, yahooProvider }; // Export the provider
export default fire;
