import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import LoadingSpinner from "../../common/loadingSpinner";
import {
  getCurrentUser,
  getCurrentUserData,
  userLogout,
} from "../../../services/user";
import { logEvent } from "../../../services/log";
import { isValidFullname } from "../../../services/validation";
import countryList from "react-select-country-list";
import Footer from "../../common/footer";
import {
  getWebsiteFullDescription,
  getWebsiteName,
  getWebsitePrefix,
  getWebsiteSuffix,
} from "../../../services/settings";
import { getLatestVersion } from "../../../services/getversion";
import ReloadModal from "../../common/reloadModal";
import { isReservedWord } from "../service/user";
import { Button, Modal } from "react-bootstrap";
import RefreshIcon from "@material-ui/icons/Refresh";
import Logo from "../component/logo";

class RegisterIdentityForm extends Form {
  state = {
    countries: countryList()
      .getData()
      .map((item) => {
        return { _id: item.value, name: item.label };
      }),
    showpass: false,
    showmodal: false,
    data: {
      fullname: "",
      userhandle: "",
      country: "",
      bio: "",
    },
    refid: this.props?.match?.params?.refid || "",
    errors: {},
    loading: true,
    version: null,
    emailaccountcreationfailed: false,
    accountcreateerror:
      "Something went wrong. Please try to login, reset your password or use another account.",
    unrecoverableerror: "We are unable to create your account.",
    emailsenterror:
      "There was a problem sending to your email account. Please try to reset your password or use another account.",
  };

  schema = {
    fullname: Joi.string().trim().required().label("Fullname"),
    userhandle: Joi.string()
      .trim()
      .min(3)
      .regex(/^([a-zA-Z0-9_-]+)$/)
      .required()
      .label("Username"),
    country: Joi.string().trim().required().label("Country"),
    bio: Joi.string().trim().required().label("Position or Rank"),
  };

  doSubmit = async () => {
    console.log("Submit");
    const { data, errors } = this.state;

    //trim spaces
    let fullname = data.fullname.trim();
    let userhandle = data.userhandle.trim().toLowerCase();
    let country = data.country.trim();
    let headline = data.bio.trim();
    let error;

    if (isReservedWord(userhandle)) {
      error = { userhandle: "Please use another Username." };
      this.setState({ errors: error, loading: false });
      return;
    }
    const result = userhandle.match(/^([a-zA-Z0-9_-]+)$/);
    // console.log(result);
    let isvalidfullname = isValidFullname(fullname);
    if (!result) {
      // "Min 3 characters. Only dash, underline and alphanumeric characters allowed.",
      const msg =
        "Minimum 3 characters required. Only use A-Z, a-z, 0-9, '-' and '_'";
      error = { userhandle: msg };
      this.setState({ errors: error, loading: false });
      return;
    }
    //check if firstname and last name are empty
    if (fullname === "" || !isvalidfullname) {
      const msg = fullname === "" ? "Please provide Name" : "Valid Name only.";
      error = { fullname: msg };
      this.setState({ data, errors: error, loading: false });
      return;
    }

    const user = await fire
      .firestore()
      .collection("users")
      .where("userhandle", "==", userhandle)
      .get();

    const list = [];
    user.forEach((doc) => {
      list.push(doc.data());
    });

    if (list.length) {
      const msg = `Someone has already used ${userhandle}. Please use another Username.`;
      error = { userhandle: msg };
      this.setState({ data, errors: error, loading: false });
      return;
    }

    this.setState({
      data: {
        fullname,
        userhandle: userhandle?.trim(),
        country,
        headline,
      },
      errors,
      loading: true,
    });

    const curUser = await getCurrentUser();
    //save to DB
    const userid = curUser.uid;
    await curUser.updateProfile({ displayName: fullname });
    const provider = curUser.providerData[0].providerId || "";
    const uniqNum = new Date().getTime();
    // console.log("Writing to users collection ");
    //Log to user DB and indicate email verified: false
    await fire
      .firestore()
      .doc(`users/${userid}`)
      .update({
        userid: userid,
        email: curUser.email,
        emailContactable:
          provider === "google.com" || provider === "yahoo.com" ? true : false,
        excludefromemail: false,
        fullname,
        country,
        bio: headline,
        userhandle: userhandle?.trim(),
        edu: [],
        exp: [],
        lastupdated: uniqNum,
        created: uniqNum,
      });

    logEvent("user_account_register_identity", { email: curUser.email });
    let link = `/home`;
    // if (refid) {
    //   link = `/a/${refid}`;
    // }
    console.log("Redirect to home....");
    this.props.history.push(link);
    return;
  };

  async componentDidMount() {
    let el = document.querySelector("title");
    const title = `${getWebsiteName()}`;
    const content = getWebsiteFullDescription();
    el.innerText = title;
    el = document.querySelector("meta[name='description']");
    el.setAttribute("content", content);

    getLatestVersion().then((version) => {
      this.setState({
        // countries,
        version,
      });
    });
    const curUser = await getCurrentUser();
    if (curUser) {
      // console.log(curUser);
      //get data from current user
      const data = await getCurrentUserData(curUser.uid);
      // console.log(data);
      if (data) {
        //redirect to home
        if (data?.userhandle && data.userhandle !== "") {
          // console.log(data);
          this.props.history.push("/home");
          return;
        } else {
          this.setState({
            data: {
              fullname: data?.fullname || "",
              userhandle: data?.userhandle || "",
              country: data?.country || "",
              bio: data?.bio || "",
            },
          });
        }
      }
    }
    this.setState({
      loading: false,
    });
    window.scrollTo(0, 0);
  }

  handleSelectCountry = (code) => {
    this.setState({ country: code });
  };

  render() {
    const { loading, countries, refid, version } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        {version && <ReloadModal version={version} />}

        <div className="my-4 pt-4"></div>

        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-0">
                <div className="text-center">
                  <Logo size={50} />
                  <h1 className="mb-0 pb-0 font-weight-bold text-primary pointer">
                    {getWebsitePrefix()}
                    <span className="text-muted font-weight-normal">
                      {getWebsiteSuffix()}
                    </span>
                  </h1>
                </div>
                <h4 className="pt-3 pb-0 mb-0 text-center">
                  Thank you for signin up!
                </h4>
                <h5 className="pt-4 pb-0 mb-4">Now, let's get you started!</h5>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "bio",
                    "Position or Rank",
                    "text",
                    "ex. 2nd Officer w/Master License"
                  )}
                  {this.renderInput(
                    "fullname",
                    "Fullname",
                    "text",
                    "Your Fullname"
                  )}
                  {this.renderInput(
                    "userhandle",
                    "Username",
                    "text",
                    "Ex. donaldcris"
                  )}

                  {this.renderSelect("country", "Country", countries)}

                  <br />
                  {!loading && this.renderBlockButton("Next")}
                  {loading && this.renderLoadingBlockButton("Please wait...")}
                </form>
              </div>
              <div className="text-center averagefontsize mt-4">
                <button
                  className="btn btn-link"
                  onClick={async () => {
                    await userLogout();
                    this.props.history.push("/home");
                  }}>
                  Log Out
                </button>
              </div>
              <div className="my-4 py-4">
                <hr />
              </div>
              <Footer />
            </main>
          </div>
        </div>
        <Modal
          show={this.state.showmodal}
          onHide={() => this.setState({ showmodal: false })}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Body className="my-4 text-center">
            <RefreshIcon
              className="text-primary"
              style={{ fontSize: "100px" }}
            />
            <h4 className="text-dark">Please reload page.</h4>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant="primary"
              className="forcenoline noline mr-2 roundcornernocolorbutton"
              onClick={() => window.location.reload()}>
              <span className="">Reload</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RegisterIdentityForm;
