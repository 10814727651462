import React from "react";
import "../../assets/css/spin.css";
import { getWebsitePrefix, getWebsiteSuffix } from "../../services/settings";
import Logo from "../account/component/logo";

export default function NavBarWithSpinner() {
  return (
    <div style={{ flexGrow: 1 }}>
      <nav
        className="text-primary  font-weight-bold"
        style={{
          display: "flex",
          padding: "15px 0px 15px 16px",
          backgroundColor: "#ffffff",
          color: "white",
          boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.05)",
        }}>
        <div>
          <Logo size={30} /> {getWebsitePrefix()}
          <span className="text-muted">{getWebsiteSuffix()}</span>
        </div>
      </nav>
      <div
        style={{
          position: "absolute",
          top: "39%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}>
        <div className="text-center d-flex justify-content-center align-items-center flex-column">
          <div
            className="spinner"
            style={{
              marginTop: "18px",
              border: "4px solid #007bff",
              borderTop: "4px solid #F0F8FF",
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              animation: "spin 1s linear infinite",
            }}></div>
          Please wait...
        </div>
      </div>
    </div>
  );
}
