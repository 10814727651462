import React, { useState, useEffect } from "react";
const yahooLogo = require("../../img/yahoo/yahoologo.png");

const YahooSignInButton = ({ handler }) => {
  const [ripples, setRipples] = useState([]);

  const buttonStyle = {
    borderRadius: "24px",
    width: "100%",
    height: "50px",
    backgroundColor: "#fff",
    border: "1px solid #c0c0c0",
    padding: "8px 12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.2s",
    fontWeight: "bold",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.1), 0 1px 1px 0 rgba(0,0,0,0.1)",
    position: "relative",
    overflow: "hidden",
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    paddingLeft: "8px",
    zIndex: 1,
  };

  const textStyle = {
    fontSize: "17px",
    fontFamily: "Roboto, arial, sans-serif",
    color: "#3c4043",
    fontWeight: "bold",
    letterSpacing: "0.21px",
    lineHeight: "40px",
    textAlign: "center",
  };

  const rippleStyle = {
    position: "absolute",
    borderRadius: "50%",
    transform: "scale(0)",
    animation: "ripple 0.6s linear",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  };

  const createRipple = (event) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    const size = Math.max(button.offsetWidth, button.offsetHeight);

    const ripple = {
      x: event.clientX - rect.left - size / 2,
      y: event.clientY - rect.top - size / 2,
      size: size,
      id: Date.now(),
    };

    setRipples((prevRipples) => [...prevRipples, ripple]);
  };

  useEffect(() => {
    const cleanup = setTimeout(() => {
      if (ripples.length > 0) {
        setRipples([]);
      }
    }, 1000);

    return () => clearTimeout(cleanup);
  }, [ripples]);

  return (
    <>
      <style>
        {`
          @keyframes ripple {
            to {
              transform: scale(4);
              opacity: 0;
            }
          }
        `}
      </style>
      <button
        className="btn gsi-material-button p-0"
        style={buttonStyle}
        onClick={(e) => {
          createRipple(e);
          if (handler) handler(e);
        }}>
        {ripples.map((ripple) => (
          <span
            key={ripple.id}
            style={{
              ...rippleStyle,
              left: ripple.x,
              top: ripple.y,
              width: ripple.size,
              height: ripple.size,
            }}
          />
        ))}
        <div style={wrapperStyle}>
          <div>
            <img
              src={yahooLogo}
              alt=""
              style={{ marginLeft: "-10px" }}
              width={`32px`}
              height={`auto`}
            />
          </div>
          <span style={textStyle}>Continue with Yahoo</span>
        </div>
      </button>
    </>
  );
};

export default YahooSignInButton;
