import React, { useState, useEffect } from "react";

const GoogleSignInButton = ({ handler }) => {
  const [ripples, setRipples] = useState([]);

  const buttonStyle = {
    borderRadius: "24px",
    width: "100%",
    height: "50px",
    backgroundColor: "#fff",
    border: "1px solid #c0c0c0",
    padding: "8px 12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.2s",
    fontWeight: "bold",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.1), 0 1px 1px 0 rgba(0,0,0,0.1)",
    position: "relative",
    overflow: "hidden",
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    paddingLeft: "8px",
    zIndex: 1,
  };

  const iconStyle = {
    width: "18px",
    height: "18px",
    display: "block",
    marginRight: "8px",
    flexShrink: 0,
  };

  const textStyle = {
    fontSize: "17px",
    fontFamily: "Roboto, arial, sans-serif",
    color: "#3c4043",
    fontWeight: "bold",
    letterSpacing: "0.21px",
    lineHeight: "40px",
    textAlign: "center",
  };

  const rippleStyle = {
    position: "absolute",
    borderRadius: "50%",
    transform: "scale(0)",
    animation: "ripple 0.6s linear",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  };

  const createRipple = (event) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    const size = Math.max(button.offsetWidth, button.offsetHeight);

    const ripple = {
      x: event.clientX - rect.left - size / 2,
      y: event.clientY - rect.top - size / 2,
      size: size,
      id: Date.now(),
    };

    setRipples((prevRipples) => [...prevRipples, ripple]);
  };

  useEffect(() => {
    const cleanup = setTimeout(() => {
      if (ripples.length > 0) {
        setRipples([]);
      }
    }, 1000);

    return () => clearTimeout(cleanup);
  }, [ripples]);

  return (
    <>
      <style>
        {`
          @keyframes ripple {
            to {
              transform: scale(4);
              opacity: 0;
            }
          }
        `}
      </style>
      <button
        className="btn gsi-material-button p-0"
        style={buttonStyle}
        onClick={(e) => {
          createRipple(e);
          if (handler) handler(e);
        }}>
        {ripples.map((ripple) => (
          <span
            key={ripple.id}
            style={{
              ...rippleStyle,
              left: ripple.x,
              top: ripple.y,
              width: ripple.size,
              height: ripple.size,
            }}
          />
        ))}
        <div style={wrapperStyle}>
          <div style={iconStyle}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              style={{ display: "block", width: "100%", height: "100%" }}>
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              />
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              />
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              />
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              />
              <path fill="none" d="M0 0h48v48H0z" />
            </svg>
          </div>
          <span style={textStyle}>Continue with Google</span>
        </div>
      </button>
    </>
  );
};

export default GoogleSignInButton;
