import React from "react";

function Logo({ size }) {
  let logoSrc;
  let newSize = size;
  if (size === 30) {
    logoSrc = require("../../../img/logo/seamanbioph-logo-c-90.png");
    newSize = 30;
  } else if (size === 50 || size === 60) {
    logoSrc = require("../../../img/logo/seamanbioph-logo-c-120.png");
  } else if (size === 120) {
    logoSrc = require("../../../img/logo/seamanbioph-logo-c-120.png");
  } else if (size === 200) {
    logoSrc = require("../../../img/logo/seamanbioph-logo-c-200.png");
  } else {
    logoSrc = require("../../../img/logo/seamanbioph-logo-c-120.png");
  }

  return (
    <img src={logoSrc} alt="Logo" width={`${newSize}px`} height={`auto`} />
  );
}

export default Logo;
