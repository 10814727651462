import React from "react";
const images = [
  {
    title: "Easy-to-use CV Maker",
    img: "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fuseai.png?alt=media&token=4d7cfe0d-6491-446d-b1f1-6219e9caceab",
  },
  {
    title: "Professional Designs",
    img: "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Ffreedownload.jpg?alt=media&token=456ddc2b-35b7-4713-aea1-9ed8988e2821",
  },
  {
    title: "Direct Apply to Jobs",
    img: "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fapplyjobs.jpg?alt=media&token=f8217e78-845d-4200-8147-acaf4ee05045",
  },
  {
    title: "Direct Apply to Agencies",
    img: "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fapplyagencies.jpg?alt=media&token=4740a0c6-90f1-4046-a940-0f8b644daf0f",
  },
  {
    title: "Ready-made Cover Letter",
    img: "https://firebasestorage.googleapis.com/v0/b/seaman-ph.appspot.com/o/cdn%2Ftestimonials%2Fcoverletter.jpg?alt=media&token=aa64808b-417c-406a-bbe9-dc23da0cc6c3",
  },
];

function FeatureImage({ css = "" }) {
  return (
    <div className={css}>
      {images.map((i, index) => (
        <div key={index} className="mb-4">
          <h2 className="mt-0 mb-3 text-left">{i.title}</h2>

          <div className="mt-2 mb-4 responsive">
            <img
              className="responsive img-fluid"
              style={{
                borderRadius: "8px",
                boxShadow: "1px 1px 10px -4px #888888",
              }}
              src={i.img}
              alt={""}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeatureImage;
