import React, { useState, useEffect } from "react";

const SpreadWordTyping = () => {
  const messages = [
    "Help spread the word",
    "Help spread SeamanBio.PH",
    "Help share SeamanBio.PH",
    "Help our fellow Seafarers",
    "Sharing is caring!",
    "Create CV for FREE!",
    "Using only your Phone!",
    "No laptop required!",
  ];

  const [currentText, setCurrentText] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    const timer = setTimeout(() => {
      const currentMessage = messages[messageIndex];

      if (!isDeleting) {
        if (currentText !== currentMessage) {
          setCurrentText(currentMessage.slice(0, currentText.length + 1));
          setTypingSpeed(150);
        } else {
          // Wait before starting to delete
          setTypingSpeed(1000);
          setIsDeleting(true);
        }
      } else {
        if (currentText === "") {
          setIsDeleting(false);
          setMessageIndex((prev) => (prev + 1) % messages.length);
          setTypingSpeed(150);
        } else {
          setCurrentText(currentMessage.slice(0, currentText.length - 1));
          setTypingSpeed(50);
        }
      }
    }, typingSpeed);

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, messageIndex, typingSpeed]);

  return (
    <div className="font-bold text-lg pt-4 pb-2 text-left">
      <span role="img" aria-label="ship" className="mr-2">
        🚢
      </span>
      {currentText}
      <span className="animate-pulse">|</span>
    </div>
  );
};

export default SpreadWordTyping;
