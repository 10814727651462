import React from "react";
import loadable from "@loadable/component";
import NavBarWithSpinner from "../../common/navBarwithSpinner";

function AsyncTerms({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/terms"));
  return <AsyncLoad {...rest} fallback={<NavBarWithSpinner />} />;
}

export default AsyncTerms;
