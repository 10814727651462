// src/utils/browserRedirect.js
export const isInAppBrowserV2 = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Detect Facebook in-app browser
  if (userAgent.indexOf("FBAN") > -1 || userAgent.indexOf("FBAV") > -1) {
    return true;
  }

  // Detect Instagram in-app browser
  if (userAgent.indexOf("Instagram") > -1) {
    return true;
  }

  // Detect Line in-app browser
  if (userAgent.indexOf("Line") > -1) {
    return true;
  }

  // Detect WhatsApp in-app browser
  if (userAgent.indexOf("WhatsApp") > -1) {
    return true;
  }

  return false;
};

export const redirectToBrowser = () => {
  const currentURL = window.location.href;

  // For Android
  if (/Android/i.test(navigator.userAgent)) {
    try {
      // Try Chrome first
      window.location.replace(
        `intent://${window.location.host}${window.location.pathname}${
          window.location.search
        }#Intent;scheme=${window.location.protocol.slice(
          0,
          -1
        )};package=com.android.chrome;S.browser_fallback_url=${encodeURIComponent(
          currentURL
        )};end`
      );
    } catch (e) {
      // If there's any error, fall back to default browser
      window.location.replace(currentURL);
    }
  }
  // For iOS
  else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    // Try Chrome first
    window.location.replace(
      `googlechrome://${window.location.host}${window.location.pathname}${window.location.search}`
    );

    // Fall back to Safari after a short delay
    setTimeout(() => {
      window.location.replace(currentURL);
    }, 1000);
  }
  // For all other platforms
  else {
    // Just open in default browser
    window.location.replace(currentURL);
  }
};
