import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import LoadingSpinner from "../../common/loadingSpinner";
import SystemLogo from "../../blocks/systemLogoBlock";
import {
  createWithEmailAndPassword,
  isUserLoggedIn,
} from "../../../services/user";
import { dump, logEvent, logMessage } from "../../../services/log";
import { Link } from "react-router-dom";
import Footer from "../../common/footer";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import {
  getWebsiteFullDescription,
  getWebsiteName,
} from "../../../services/settings";
import { getLatestVersion } from "../../../services/getversion";
import CustomBack from "../../common/customBack";
import ReloadModal from "../../common/reloadModal";
import { Button, Modal } from "react-bootstrap";
import RefreshIcon from "@material-ui/icons/Refresh";
import BasicMediaDetails from "../../common/basicMediaDetails";
import fire from "../../../services/fire";
import OtherSignInButtons from "../../common/otherSignInButtons";
const validator = require("email-validator");

class RegisterEmailForm extends Form {
  state = {
    showpass: false,
    showmodal: false,
    data: {
      email: "",
      password: "",
      confirmpassword: "",
    },
    refid: this.props?.match?.params?.refid || "",
    errors: {},
    loading: true,
    version: null,
    emailaccountcreationfailed: false,
    accountcreateerror:
      "Something went wrong. Please try to login, reset your password or use another account.",
    unrecoverableerror: "We are unable to create your account.",
    emailsenterror:
      "There was a problem sending to your email account. Please try to reset your password or use another account.",
  };

  schema = {
    email: Joi.string().trim().email().required().label("Email"),
    password: Joi.string().trim().required().min(8).label("Password"),
    confirmpassword: Joi.string()
      .trim()
      .required()
      .min(8)
      .label("Confirm Password"),
  };

  doSubmit = async () => {
    console.log("Submit");
    const { data, errors, refid } = this.state;

    //trim spaces
    let email = data.email.toLowerCase().trim();
    let password = data.password.trim();
    let confirmpassword = data.confirmpassword.trim();
    let error;

    if (!validator.validate(email)) {
      error = { email: "Invalid Email" };
      this.setState({ errors: error, loading: false });
      return;
    }
    console.log(password, confirmpassword);
    console.log(password, confirmpassword);
    //check if emails match
    if (password !== confirmpassword) {
      error = { confirmpassword: "Passwords do not match." };
      this.setState({ data, errors: error, loading: false });
      return;
    }

    this.setState({
      data: {
        email,
        password,
        confirmpassword,
      },
      errors,
      loading: true,
    });

    // Call the server
    try {
      let result = await createWithEmailAndPassword(email, password);
      console.log(result);

      if (result && result.code === "success") {
        logEvent("create_email_password_success", { email: email });
        // const user = result.user;

        // let userid = user.uid;

        // try {
        //   const uniqNum = new Date().getTime();
        //   // console.log("Writing to users collection ");
        //   //Log to user DB and indicate email verified: false
        //   await fire.firestore().doc(`users/${userid}`).set({
        //     emailContactable: false,
        //     email,
        //     fullname,
        //     country,
        //     bio: headline,
        //     //default: light blue
        //     background: 7,
        //     created: uniqNum,
        //     userid: userid,
        //     userhandle: userhandle?.trim(),
        //     edu: [],
        //     exp: [],
        //     lastupdated: new Date().getTime(),
        //   });

        //   console.log("User created...");
        //   logEvent("user_account_create_success", { email: email });
        //   let link = `/home`;
        //   // if (refid) {
        //   //   link = `/a/${refid}`;
        //   // }
        //   console.log("Redirect to home....");
        //   this.props.history.push(link);
        //   return;
        //   // console.log("Before sending email verification");
        //   // try {
        //   //   // await user.sendEmailVerification();
        //   //   // console.log("Done sending email verification");
        //   //   // logEvent("auto_send_email_verification_success", {
        //   //   //   email: email,
        //   //   // });
        //   //   //Route /home will check if email is verified, if not, show Account Verify page
        //   //   // let link = `/welcome`;
        //   //   let link = `/home`;
        //   //   // if (refid) {
        //   //   //   link = `/a/${refid}`;
        //   //   // }
        //   //   console.log("Redirect to home....");
        //   //   this.props.history.push(link);
        //   // } catch (error) {
        //   //   logEvent("auto_send_email_verification_fail", {
        //   //     email: email,
        //   //     message: error.message,
        //   //   });
        //   //   console.error("Error sending email: ", error);

        //   //   this.setState({
        //   //     emailaccountcreationfailed: true,
        //   //     loading: false,
        //   //     errors: { email: this.state.emailsenterror },
        //   //   });
        //   // }
        // } catch (ex) {
        //   console.error("Error creating email and password: ", ex);
        //   logEvent("user_account_create_fail", {
        //     email: email,
        //     message: ex.message,
        //   });
        //   await logMessage("create_email_and_password_exception_fail", {
        //     email: email,
        //     message: ex.message,
        //     code: ex.code,
        //   });
        //   this.setState({
        //     emailaccountcreationfailed: true,
        //     loading: false,
        //     errors: { email: this.state.accountcreateerror },
        //   });
        // }
      } else {
        logEvent("create_email_password_fail", {
          email: email,
          message: result.message,
        });
        let showModal = false;
        if (result?.intercept === true) {
          await dump({ userid: "", email: email }, "register", {
            code: result.code || "",
            message: result.message || "",
            result: result,
            notes: "createWithEmailAndPassword has unknown error",
          });
          showModal = true;
        }
        await logMessage("create_email_and_password_fail", {
          email: email,
          message: result.message,
          code: result.code,
        });
        console.log("Error:", result);
        this.setState({
          showmodal: showModal,
          emailaccountcreationfailed: true,
          loading: false,
          errors: result,
        });
      }
    } catch (ex) {
      console.log("Errors:", ex);

      logEvent("create_email_password_exception_fail", {
        email: email,
        message: ex.message,
      });
      await logMessage("create_email_and_password_exception_fail", {
        email: email,
        message: ex.message,
        code: ex.code,
      });
      if (ex.hasOwnProperty("code")) {
        console.log("Errors:", ex);
        let errors;
        if (ex.code === "auth/too-many-requests") {
          errors = { email: ex.message };
        } else {
          errors = {
            email: ex.message,
          };
        }
        this.setState({
          loading: false,
          errors,
        });
      } else {
        this.setState({
          loading: false,
          errors: { email: this.state.unrecoverableerror },
        });
      }
    }
  };

  async componentDidMount() {
    await fire.auth().getRedirectResult();
    const loggedIn = await isUserLoggedIn();
    // console.log("Logged in: ", loggedIn);
    let el = document.querySelector("title");
    const title = `${getWebsiteName()}`;
    const content = getWebsiteFullDescription();
    el.innerText = title;
    el = document.querySelector("meta[name='description']");
    el.setAttribute("content", content);

    if (loggedIn) {
      // console.log("Logged in");
      this.props.history.push("/home");
      return;
    }
    getLatestVersion().then((version) => {
      this.setState({
        version,
      });
    });
    this.setState({
      loading: false,
    });
    window.scrollTo(0, 0);
  }

  render() {
    const { loading, refid, version } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        {version && <ReloadModal version={version} />}

        <div className="my-4 pt-0"></div>
        <CustomBack text="Home" link="/welcome" />

        <SystemLogo refid={refid} />
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-6">
            <main className="container">
              <div className="pb-3 pt-2">
                <h4 className="pb-0 mb-0">Join Now</h4>
                <p className="text-left text-muted mb-4">
                  It's FREE. No laptop required.
                </p>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "email",
                    `Email ${this.state.data.email}`,
                    "text",
                    "Your email"
                  )}

                  {this.renderInput(
                    "password",
                    "Password",
                    this.state.showpass ? "text" : "password",
                    "8 characters or more"
                  )}

                  <div
                    onClick={() =>
                      this.setState({ showpass: !this.state.showpass })
                    }
                    className="float-right pointer btn-link mb-4"
                    style={{ marginTop: "-15px" }}>
                    {this.state.showpass === false ? (
                      <>
                        <VisibilityOutlinedIcon /> Show Password
                      </>
                    ) : (
                      <>
                        <VisibilityOffOutlinedIcon /> Hide Password
                      </>
                    )}
                  </div>
                  {this.renderInput(
                    "confirmpassword",
                    "Confirm Password",
                    this.state.showpass ? "text" : "password",
                    "8 characters or more"
                  )}
                  {/* <div
                    className="px-2  alert alert-white"
                    style={{ border: "1px dashed #a0a0a0" }}>
                    <Media className="mb-1">
                      <span className="mr-1" role="img" aria-label="caution">
                        🔔
                      </span>
                      <Media.Body className="standardfontsize">
                        Make sure your EMAIL is VALID and your INBOX is NOT
                        FULL! When you apply & got shortlisted, agencies will
                        contact you thu this email.
                      </Media.Body>
                    </Media>
                  </div> */}
                  <div className="mt-4 mb-4 standardfontsize">
                    <p>By clicking Join Now:</p>
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="You can create and download FREE CV"
                    />
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="You can Apply Direct to DMW-listed Agencies"
                    />
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="You can Apply Jobs by Rank"
                    />
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="You can receive Job Alerts"
                    />
                    <BasicMediaDetails
                      lead={<>✅</>}
                      value="When you apply & got shortlisted, agencies will contact you"
                    />
                  </div>
                  {!loading && this.renderBlockButton("Join Now")}
                  {loading && this.renderLoadingBlockButton("Please wait...")}
                </form>
              </div>

              <div className="pt-3 pb-4 text-center">
                <p className="averagefontsize">
                  Already have an account?{" "}
                  <Link
                    className=" text-danger font-weight-bold"
                    to={`/login/${refid}`}>
                    Log in
                  </Link>
                </p>
                <div className="pt-2">
                  <OtherSignInButtons />
                </div>
              </div>
              <div className="my-4 py-4">
                <hr />
              </div>
              <Footer />
            </main>
          </div>
        </div>
        <Modal
          show={this.state.showmodal}
          onHide={() => this.setState({ showmodal: false })}
          animation={false}
          centered
          backdrop="static"
          keyboard={true}>
          <Modal.Body className="my-4 text-center">
            <RefreshIcon
              className="text-primary"
              style={{ fontSize: "100px" }}
            />
            <h4 className="text-dark">Please reload page.</h4>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <Button
              variant="primary"
              className="forcenoline noline mr-2 roundcornernocolorbutton"
              onClick={() => window.location.reload()}>
              <span className="">Reload</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RegisterEmailForm;
