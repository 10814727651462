import React from "react";
import OtherSignInButtons from "./otherSignInButtons";
import { Link } from "react-router-dom";

function ActionButtons({ refid = "" }) {
  console.log("Action Button called");
  return (
    <div>
      <p className="font-weight-bolds text-center text-dark mb-2 mt-4">
        Sign In
      </p>
      <OtherSignInButtons />
      <p className="font-weight-normal text-center text-dark mb-0 mt-3">
        or continue with email
      </p>
      <div className="pt-4">
        <Link
          className="font-weight-bold btn-success btn-lg btn-block px-4 mb-4 roundcornernocolorbutton"
          to={`/register/${refid}`}>
          Join Now - It's FREE
        </Link>
      </div>
      <div className="mt-4 mb-3">
        <Link
          style={{ border: "2px dashed #007bff" }}
          className="font-weight-bold btn btn-outline-primary btn-lg btn-block px-4 mb-0 roundcornernocolorbutton"
          to={`/login/${refid}`}>
          Log In
        </Link>
      </div>
    </div>
  );
}

export default ActionButtons;
