import countryList from "react-select-country-list";
import { enableTravelsForAll } from "../../../services/settings";
import {
  getCurrentUser,
  getCurrentUserData,
  userLogout,
} from "../../../services/user";
import { isValidFullname } from "../../../services/validation";
import { isSuperAdminRole } from "../../roles/service/roles";

const COUNTRIES = countryList().getData();

export async function getUserData(curUser) {
  let uid = curUser?.uid;
  // uid = "FIUYjd1e6oP3Ya4FI8wONq6htiJ3";
  // uid = "6GjJHaqS1kgmcbZZbzz4sNOBeeo1";
  // uid = "EdwZuyhe9md2gY53Thp3j6zEQsP2"; //awards
  // uid = "gNeuFEaFO3ei4oRYJJyZZdSGRZJ3";
  // uid = "UtSjPJThcaS5kgJwLr4SfcVfEJy2";
  // uid = "B7PYTMSsY5PObhz7VBaIbJ3zzg63";
  // uid = "YEJP4e0pEiZg223ysbrzhhiwePl2";
  // uid = "v0jV8TLsjuXBlrNTaYtu0wKeCuY2";
  // uid = "60TRELufznWhWuHU3JzJbZrsOTk2";
  // uid = "Ke7De1ssi4WmCM5iy3Zgk4MBUCE2"; //testimonial
  // uid = "QItejr9wP7QiA3Hy56pzotP0ZhE3";
  // uid = "Mlpnov4nPtPLplff2QsTjmDSwm52"; //testimonial
  // uid = "sltx6VZfWwdxrIv6xC0qsramzQE2"; //eto
  // uid = "Bdab3vA82JRnpaIL3jx41JVsefC2";
  // uid = "mpLCGTCDiygn2iVIqGR1f1PTJug2"; //long bio
  // uid = "Zniimv1aqSSrtRR6EjFJ7eOzP5N2";
  // uid = "JBPbe2F3N5fAMQyCl3tLwSeiIU32";
  // uid = "4jQAUJeBgAcr5w08NbIHIbKSWqN2";
  // uid = "4BdKWuQ8V9ZhOeG50fIcsIAGtPa2";
  // uid = "SJWUhRzq3LRoYhDqtLEwXHN4mv52";
  // uid = "OMB40P0bDPgYqG2l9dOjvrXojtH3";
  // uid = "BXfKnV1sgpR2WFVIAWcD7WAbifd2";
  // uid = "TeSz2qnyuparvM8xHNl4n5dK7X22";
  // uid = "uUnecPc5hWaAHvh95OKuDXnFNOf2"; //22 exp
  // uid = "2u4My1y7vmgLl4XB9dwuX7KwvIt2";
  // uid = "gb5Dgr4ATHYT7SQzxTfqQ6KGyh13";
  // uid = "ky7qOt9sAgUVB7KnetfpWcvLaGg2";
  // uid = "3E2weJFCNdQeT0MmDdEBkF5DTdy1";
  // uid = "NH42RkCBKvPEMF4Qt0VQyO6j3lm2";
  // uid = "msqveJG6k9bYzycOsQ8akIyD0Yr2";
  // uid = "S84FeHNLbrTL49zun1ahUZ1ddHg2";
  // uid = "9JxIMp67uEQkWWwAiaAVHFt9c2A3";
  // uid = "COkZAjckQof9xDBHyTc0o5Z7lU13";
  // uid = "RxVRWlLQVvbtbiB7rDG00mv0dQP2";
  // uid = "rAcRh1lgo3aX9FpLMiRLW6hEf6z1"; //d
  // uid = "4NvzCsl204hB2dAmE4pmyB0HCSb2";
  // uid = "wji5XwOr4UYwJwGup6gMYrsBhvG2";
  // uid = "xoQ7SVudGjZjQq0Fp3EgefBJ6Zw2";
  // uid = "K8IAFyOH1HX5zK33Eg99f4SewI62";
  // uid = "wR2Jt5lySGfNM2hRfhSUsn24Eqz2";
  // uid = "5h5uUapjQLVhz3hY8lrO01f8hJX2";
  // uid = "MHNHREx2SLRCzshWDynH0voMUO73";
  // uid = "QZ8P0jDh14TEzCSt9PHtAmPMkTO2";
  // uid = "ZWJHPi0Z1QaN9pQZZbbpKKc9RN03";
  // uid = "hdTrzaIETpOytuYIyJNPqmY9gQw1";
  // uid = "1xPaT86sV8WksZGHi8yHKGjxyQH2";
  // uid = "1dLWPePpGgYcWw98EGP1MrZ6c6H3";
  // uid = "NcWIosvN8gadqQGD2wVJZqp8W953";
  // uid = "KFvqIkJYSWcbKkWCila5ubvYJVE2";
  // uid = "4cHnpym36FTXUlH9KS4UVMrDadU2";
  // uid = "PreTR4MyZkaeelMNnAympskuvj63";
  // uid = "rAcRh1lgo3aX9FpLMiRLW6hEf6z1";
  // uid = "BXfKnV1sgpR2WFVIAWcD7WAbifd2";
  // uid = "NcWIosvN8gadqQGD2wVJZqp8W953";
  // uid = "4zcoM0VDdPOsdLsPKZiGv7nAfkn2";

  const user = await getCurrentUserData(uid);

  if (user) {
    const {
      vac,
      train,
      travel,
      exp,
      wexp,
      edu,
      bio,
      fullname,
      email,
      currentpos,
      bigpic,
      country,
      init,
      smallpic,
      school,
      degree,
      address,
      phonenumber,
      currentcompanydesc,
      currentcompany,
      userhandle,
      verified,
      background,
      dob,
      height,
      weight,
      civilstatus,
      license,
      objective,
      srn,
      otherids,
      reference,
      showtravels,
      travellog,
      visible,
      hasrequestemailverify,
      hasseenuploadcv,
      gender,
      yearsinrank,
      availability,
      seenuploadcv,
      lastverification,
      autopos,
      signature,
      trysignature,
      mystat,
      lastupdated,
      searchability,
    } = user;

    const isvalidfullname = isValidFullname(fullname);
    const photoUrl = bigpic;
    const countries = COUNTRIES;

    let countryStr = "";
    if (countries && country !== "") {
      //get country str
      for (let i = 0; i < countries.length; i++) {
        const item = countries[i];
        if (item.value === country) {
          countryStr = item.label;
          break;
        }
      }
    }

    const tempBackground =
      background >= 0 && background <= 10 ? background : 10;

    //check if super admin
    const access = await getCurrentUser();
    const isSpecial = await isSuperAdminRole(access);
    const userObj = {
      isSpecial,
      countryStr,
      countries,
      isvalidfullname,
      background: tempBackground,
      prevbackground: tempBackground,
      data: {
        visible: visible || false,
        travellog: travellog || [],
        reference: reference || [],
        srn: srn || "",
        objective: objective || "",
        train: train || [],
        otherids: otherids || [],
        vac: vac || [],
        license: license || [],
        travel: travel || [],
        wexp: wexp || [],
        verified: verified || false,
        exp: exp || [],
        userhandle: userhandle || "",
        edu: edu || [],
        bio,
        dob,
        height,
        weight,
        civilstatus,
        fullname,
        bigpic: bigpic || "",
        smallpic: smallpic || "",
        background: tempBackground,
        prevbackground: tempBackground,
        email,
        init,
        currentpos,
        address,
        phonenumber,
        school,
        degree,
        currentcompanydesc,
        currentcompany,
        country,
        countryStr,
        hasrequestemailverify: hasrequestemailverify || false,
        hasseenuploadcv: hasseenuploadcv || false,
        gender: gender || "",
        yearsinrank: yearsinrank || "",
        availability: availability || "",
        seenuploadcv: seenuploadcv || false,
        lastverification: lastverification || 0,
        autopos: autopos || "",
        signature: signature || null,
        trysignature: trysignature || false,
        mystat: mystat || "",
        searchability: searchability || "",
        lastupdated: lastupdated || 0,
      },
      photoUrl,
      showtravels: enableTravelsForAll(showtravels || false),
    };
    return userObj;
  } else {
    await userLogout();
    return null;
  }
}

export function isReservedWord(userhandle) {
  //check if user handle is reserved word
  const reservedwords = [
    "app",
    "apps",
    "agency",
    "agencies",
    "seaman",
    "seafarer",
    "seamanbio",
    "seamanbioph",
    "android",
    "iphone",
    "ios",
    "windows",
    "fuck",
    "pussy",
    "dick",
    "vagina",
    "penis",
    "ass",
    "asshole",
    "stupid",
    "email",
    "verify",
    "contact",
    "contactus",
    "connect",
    "connections",
    "hiring",
    "hirings",
    "openings",
    "invite",
    "invites",
    "friend",
    "friends",
    "admin",
    "command",
    "commandcenter",
    "login",
    "register",
    "forgot",
    "password",
    "about",
    "logout",
    "home",
    "welcome",
    "faq",
    "v",
    "a",
    "p",
    "u",
    "user",
    "web",
    "privacy",
    "delete",
    "add",
    "sub",
    "contribute",
    "contributor",
    "welcome1",
    "profile",
    "404",
    "donation",
    "donate",
    "facebook",
    "instagram",
    "uber",
    "tiktok",
    "nokia",
    "gmail",
    "google",
    "heal",
    "sick",
    "pinoycv",
    "doctor",
    "nurse",
    "labtech",
    "magsaysay",
    "jebsenptc",
    "jebsen",
    "status",
    "lydiamar",
    "ventis",
    "arctic",
  ];

  return reservedwords.includes(userhandle);
}
