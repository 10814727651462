import React from "react";
import { Button } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import ShareIcon from "@material-ui/icons/Share";
import ShareSeamanBio from "../share/shareSeamanBio";

function SafeShare({ handleCopy }) {
  if (!navigator.share) {
    return (
      <CopyToClipboard text={"https://seamanbio.ph"} onCopy={handleCopy}>
        <Button
          variant={`text-dark`}
          className="float-right btn-sm py-2 pl-2 pr-3">
          <div className="float-left" style={{ width: "25px" }}>
            <ShareIcon
              className="text-primary"
              style={{
                fontSize: "18px",
                marginTop: "-2px",
              }}
            />
          </div>
          <div className="float-left">Share</div>
        </Button>
      </CopyToClipboard>
    );
  } else {
    return (
      <ShareSeamanBio
        title={"Share SeamanBio.ph"}
        text={"https://seamanbio.ph"}
        item={null}
      />
    );
  }
}

export default SafeShare;
