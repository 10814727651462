import React from "react";
import GoogleSignInButton from "./googleSignInButton";
import YahooSignInButton from "./yahooSignInButton";
import fire, { googleProvider, yahooProvider } from "../../services/fire";

function OtherSignInButtons() {
  const handleGoogleSignIn = () => {
    fire
      .auth()
      .signInWithRedirect(googleProvider)
      .catch((error) => {
        console.error("Error initiating Google sign-in:", error);
      })
      .finally(() => {
        // window.location.reload();
      });
  };

  const handleYahooSignIn = () => {
    fire
      .auth()
      .signInWithRedirect(yahooProvider)
      .catch((error) => {
        console.error("Error initiating Google sign-in:", error);
      })
      .finally(() => {
        // window.location.reload();
      });
  };
  return (
    <div>
      <div className="mt-1 mb-3">
        <GoogleSignInButton handler={handleGoogleSignIn} />
      </div>
      <div className="mt-0 mb-1">
        <YahooSignInButton handler={handleYahooSignIn} />
      </div>
    </div>
  );
}

export default OtherSignInButtons;
