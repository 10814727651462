import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/spinner";

function AsyncEmailUserList({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/emailUserList"));
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncEmailUserList;
