import React from "react";
// import WelcomePage1 from "./welcomePage1";
// import WelcomePage2 from "./welcomePage2";
// import WelcomePage3 from "./welcomePage3";
import WelcomePage4 from "./welcomePage4";

// const DESIGN = 3;

function WelcomePage({ ...props }) {
  // if (DESIGN === 1) {
  //   return <WelcomePage1 {...props} />;
  // } else if (DESIGN === 2) {
  //   return <WelcomePage2 {...props} />;
  // } else if (DESIGN === 3) {
  //   return <WelcomePage3 {...props} />;
  // } else if (DESIGN === 4) {
  return <WelcomePage4 {...props} />;
  // }
  // return <WelcomePage1 {...props} />;
}

export default WelcomePage;
