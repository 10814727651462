import React, { useState, useEffect } from "react";
import "../../assets/css/spin.css";
import { getRandomWaitMessage } from "../../utils/waitmessage";

const CenterSpinner = () => {
  const [message, setMessage] = useState("Please wait...");

  useEffect(() => {
    // setMessage(getRandomWaitMessage());
    const timer = setInterval(() => {
      setMessage(getRandomWaitMessage());
    }, 2000);

    // Cleanup function to clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="spinner-container">
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ marginTop: "18px" }}>
        <div className="spinner"></div>
        <div className="">{message}</div>
      </div>
    </div>
  );
};

export default CenterSpinner;
